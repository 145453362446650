import React, { FunctionComponent } from "react";

import Logo from "assets/images/logo.png";
import InstagramIcon from "components/SVG/Instagram";
import EmailIcon from "components/SVG/Email";
import FacebookIcon from "components/SVG/Facebook";
import PhoneIcon from "components/SVG/Phone";

const Home: FunctionComponent = () => {
  const year = new Date();

  return (
    <div className="home-container">
      <img
        className="logo"
        src={Logo}
        alt="Logo de Fidia.dev"
        width={80}
        height={80}
      />
      <div className="title-container">
        <h1 className="title">Fidia</h1>
        <h2 className="subtitle">Développeuse créative</h2>
      </div>

      <div className="home-content">
        <p className="home-text">
          Création de sites web et applications mobiles sur mesure ! <br />
          <span className="span">Le site est en pleine préparation ...</span>
          <br /> <br /> En attendant, n'hésite pas à me joindre pour me parler
          de ton projet !
        </p>

        <div className="social-container">
          <a href="https://www.instagram.com/fidia.dev/" target="_blank">
            <InstagramIcon width={40} height={40} />
          </a>
          <a href="mailto:hello@fidia.dev">
            <EmailIcon width={40} height={40} />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61559728241103"
            target="_blank"
          >
            <FacebookIcon width={40} height={40} />
          </a>
          <a href="tel:+33652411116">
            <PhoneIcon width={40} height={40} />
          </a>
        </div>

        <p className="ps-text">
          PS: Tu peux voir mon travail sur les réseaux ;)
        </p>
      </div>
      <p className="copyright">© {year.getFullYear()} Fidia.Dev</p>
    </div>
  );
};

export default Home;
