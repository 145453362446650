import React, { FunctionComponent } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./styles/index.scss";
import Home from "./pages/Home";

const App: FunctionComponent = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
