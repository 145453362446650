import React, { FunctionComponent } from "react";

export interface PhoneProps {
  width: number;
  height: number;
  className?: string;
  color?: string;
}

const Phone: FunctionComponent<PhoneProps> = ({
  className,
  color,
  width,
  height,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      zoomAndPan="magnify"
      viewBox="0 0 52.5 52.499999"
      height={height}
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
      className={className}
    >
      <defs>
        <clipPath id="f89531da3d">
          <path
            d="M 0 0 L 52.003906 0 L 52.003906 52.003906 L 0 52.003906 Z M 0 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="4cf3ba3574">
          <path
            d="M 14.277344 10 L 37 10 L 37 41.863281 L 14.277344 41.863281 Z M 14.277344 10 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#f89531da3d)">
        <path
          fill={color}
          d="M 26.003906 0 C 11.636719 0 0 11.648438 0 26.003906 C 0 40.355469 11.636719 52.003906 26.003906 52.003906 C 40.367188 52.003906 52.003906 40.355469 52.003906 26.003906 C 52.003906 11.648438 40.367188 0 26.003906 0 Z M 26.003906 50.359375 C 12.550781 50.359375 1.644531 39.453125 1.644531 26.003906 C 1.644531 12.550781 12.550781 1.644531 26.003906 1.644531 C 39.453125 1.644531 50.359375 12.550781 50.359375 26.003906 C 50.359375 39.453125 39.453125 50.359375 26.003906 50.359375 Z M 26.003906 50.359375 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#4cf3ba3574)">
        <path
          fill={color}
          d="M 21.234375 10.101562 C 21.234375 10.101562 14.316406 10.191406 14.40625 16.328125 C 14.496094 22.46875 15.753906 27.894531 17.820312 30.917969 C 19.886719 33.945312 25.183594 41.863281 31.207031 41.0625 C 31.207031 41.0625 34.171875 40.4375 36.414062 39.015625 L 29.140625 33.410156 C 29.140625 33.410156 26.984375 34.746094 25.636719 32.964844 C 24.289062 31.1875 20.066406 22.914062 20.066406 22.914062 C 20.066406 22.914062 18.898438 20.242188 21.503906 19.085938 Z M 21.234375 10.101562 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <path
        fill={color}
        d="M 22.042969 10.101562 C 22.042969 10.101562 22.582031 9.925781 23.570312 10.457031 C 24.558594 10.992188 24.558594 11.433594 24.558594 11.433594 L 24.648438 17.574219 C 24.648438 17.574219 24.378906 17.929688 24.019531 18.285156 C 23.660156 18.640625 22.492188 19.175781 22.3125 18.820312 C 22.132812 18.464844 22.042969 10.101562 22.042969 10.101562 Z M 22.042969 10.101562 "
        fillOpacity="1"
        fillRule="nonzero"
      />
      <path
        fill={color}
        d="M 29.742188 32.753906 L 31.746094 31.71875 C 31.746094 31.71875 32.195312 31.632812 32.910156 32.074219 C 33.628906 32.523438 37.402344 35.457031 37.402344 35.457031 C 37.402344 35.457031 38.03125 35.992188 37.851562 36.613281 C 37.671875 37.238281 37.222656 38.570312 37.222656 38.570312 Z M 29.742188 32.753906 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default Phone;
